import './PublicationTerms.css';
import React from 'react';
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";

export const PublicationTerms = () => {
    const navigate = useNavigate();

    return (
        <div className="PublicationTerms">
            <div>
                <p><FormattedMessage id={'publicationTerms'}/></p>
            </div>
            <button onClick={() => navigate('/')}>
                <FormattedMessage id={'returnToMainScreen'}/>
            </button>
        </div>
    );
};
