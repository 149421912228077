import './ForeignLanguageEditing.css';
import React from 'react';
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";

export const ForeignLanguageEditing = () => {
    const navigate = useNavigate();

    return (
        <div className="ForeignLanguageEditing">
            <div>
                <p><FormattedMessage id={'foreignLanguageEditing.editor1'}/></p>
                <p><FormattedMessage id={'foreignLanguageEditing.editor2'}/></p>
            </div>
            <button onClick={() => navigate('/')}>
                <FormattedMessage id={'returnToMainScreen'}/>
            </button>
        </div>
    );
};
