import './MainPage.css';
import React from 'react';
import greekFlag from '../../../assets/img/greekFlag.svg';
import englishFlag from '../../../assets/img/englishFlag.svg';
import {FormattedMessage, useIntl} from "react-intl";
import {useLocale} from "../LocaleContext";
import {Link} from "react-router-dom";

export const MainPage = () => {
    const intl = useIntl();
    const {changeLocale} = useLocale();
    const filenames = ["i_teleti_tou_anakalimatos", "i_ritoriki_1705",
        "satirikos_kariotakis",
        "i_ritoriki_paideia_sin_patriarxiki_sxoli",
        "i_ritoriki_anapalaiosi", "i_paralogi_tou_nekrou_aderfou"
    ]
    const sidebarLinks = [
        {url: "/contact", id: "sidebar.contactUs"},
        {url: "/copyrights", id: "sidebar.copyrights"},
        {url: "/foreign-language-editing", id: "sidebar.foreignLangEdit"},
        {url: "/scientific-committee", id: "sidebar.scienceCommittee"},
        {url: "/preface", id: "sidebar.preface"},
        {url: "/publication-terms", id: "sidebar.publicationTerms"}
    ];

    const createFilesList = () => {
        return <ol className="FilenamesList">
            {filenames.map((item, index) => (
                <li key={`mainPage.articles.files.file${index}`}>
                    <a href={`/pdfs/${item}.pdf`} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage
                            id={`mainPage.articles.files.file${index}`}
                            values={{
                                br: <br/>
                            }}/>
                    </a>
                </li>
            ))}
        </ol>
    }

    return (
        <div className="MainPage">
            <div className="Sidebar">
                <ol>
                    {sidebarLinks.map(link => (
                        <li key={link.url}>
                            <Link to={link.url}>
                                <FormattedMessage id={link.id}/>
                            </Link>
                        </li>
                    ))}
                </ol>
            </div>
            <h1 className="Title">{intl.formatMessage({id: 'mainPage.title'})}</h1>
            <h2 className="SubTitle">{intl.formatMessage({id: 'mainPage.subtitle'})}</h2>
                <div className="LanguagesAndClockWrapper">
                    <div className="ClockWrapper">
                        <iframe
                            src="https://freesecure.timeanddate.com/clock/i5agvvqz/n26/szw82/szh86/hoc000/hbw0/hfc09f/cf100/hnc07c/hwc000/facfff/fnu2/fdi76/mqcfff/mqs4/mql18/mqw4/mqd60/mhcfff/mhs4/mhl5/mhw4/mhd62/mmv0/hhcfff/hhs1/hhb10/hmcfff/hms1/hmb10/hscfff/hsw3"
                            width="82" height="86"></iframe>
                    </div>
                    <div className="LanguagesWrapper">
                        <img src={greekFlag} alt="greek flag icon" onClick={() => changeLocale("el")}/>
                        <img src={englishFlag} alt="english flag icon" onClick={() => changeLocale("en")}/>
                    </div>
                </div>
                <div className="ArticlesWrapper">
                    <h2 className="ArticlesTitle">{intl.formatMessage({id: 'mainPage.articles.title'})}</h2>
                    <p>{intl.formatMessage({id: 'mainPage.articles.authorName'})}</p>
                    {createFilesList()}
                </div>
        </div>
    );
};
