import React from 'react';
import {createBrowserRouter, RouterProvider,} from 'react-router-dom';
import {MainPage} from "./js/components/MainPage/MainPage";
import {LocaleProvider, useLocale} from "./js/components/LocaleContext";
import {IntlProvider} from "react-intl";
import enTranslation from './lang/en.json';
import elTranslation from './lang/el.json';
import {flattenMessages} from "./js/lib/util";
import {Contact} from "./js/components/Contact/Contact";
import {Copyrights} from "./js/components/Copyrights/Copyrights";
import {ForeignLanguageEditing} from "./js/components/ForeignLanguageEditing/ForeignLanguageEditing";
import {ScientificCommittee} from "./js/components/ScientificCommittee/ScientificCommittee";
import {Preface} from "./js/components/Preface/Preface";
import {PublicationTerms} from "./js/components/PublicationTerms/PublicationTerms";

function App() {
    const router = createBrowserRouter([
        {
            element: <MainPage/>,
            path: '/',
        },
        {
            element: <Contact/>,
            path: '/contact',
        },
        {
            element: <Copyrights/>,
            path: '/copyrights',
        },
        {
            element: <ForeignLanguageEditing/>,
            path: '/foreign-language-editing',
        },
        {
            element: <ScientificCommittee/>,
            path: '/scientific-committee',
        },
        {
            element: <Preface/>,
            path: '/preface',
        },
        {
            element: <PublicationTerms/>,
            path: '/publication-terms',
        }
    ]);

    const LocaleWrapper = () => {
        const { locale } = useLocale();
        const translations = {
            en: flattenMessages(enTranslation),
            el: flattenMessages(elTranslation),
        };

        return (
            <IntlProvider
                locale={locale}
                messages={translations[locale]}
            >
                <RouterProvider router={router}/>
            </IntlProvider>
        );
    };

    return (
        <LocaleProvider>
            <LocaleWrapper />
        </LocaleProvider>
    );
}

export default App;
