import './Preface.css';
import React from 'react';
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";

export const Preface = () => {
    const navigate = useNavigate();

    return (
        <div className="Preface">
            <div>
                <p><FormattedMessage id={'preface'}/></p>
            </div>
            <button onClick={() => navigate('/')}>
                <FormattedMessage id={'returnToMainScreen'}/>
            </button>
        </div>
    );
};
